html {
    height: 100%;
    width: 100%;
    margin: 0;
    
}

body {
    background: transparent linear-gradient(180deg, #F7F7FD 0%, #F9F9FE 100%) 0% 0% no-repeat padding-box;
    height: 100%;
    width: 100%;
}

.fileupload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    margin: 20px;

    .filesRequiredList {
        padding: 20px;
    }

    .dropzone {
        border-style: dashed;
        border-color: #B0BCD6;
        padding: 20px;
        background-color: #E7EFFB;
        cursor: pointer;
        text-align: center;
        border-radius: 10px;
        color: #3F3F3F;
    }

    .fileupload {
        margin: 10px;
    }

    .fileinformation {
        text-align: center;
    }

    .file-list {
        h4{
            color: black;
        }
        color: gray;
    }
}

.Form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 30px;


    .errormsg{
        color: #d8000cd1;
        padding: 2px 0;
    }

    .errorstep6 {
        text-align: center;
    }

    .icmlogo {
        max-width: 400px;
        padding-bottom: 20px;
    }
    .step {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            font-size: 2em;
        }
        .stepheader {
            color: #25253E;
            padding: 20px 20px 20px;
        }

        .acntitle {
            color: #25253E;
            font-weight: 600;
            border-top: 1px solid lightgray;
            padding-top: 20px;
            margin-bottom: 0px;
        }

        .stepform {

            input[type=text], input[type=number] {
                width: 100%;
                padding: 5px 10px;
                border: 1px solid #28347147;
                background: #F0F5FE 0% 0% no-repeat padding-box;
                border-radius: 5px;
                height: 40px;
                margin-top: 6px;
                min-width: 70vw;
                box-sizing: border-box;
                @media only screen and (min-width: 850px) {
                    min-width: 20vw;
                }
            }
        
            label {
                margin-right: auto;
                padding-top: 5px;
                letter-spacing: 0px;
                color: #25253E;
                font-weight: 600;
                margin-top: 25px;
                display: block;
            }

            .labelCheckbox {
                display: contents;
            }

            .creditorInfo {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 20px;

                h3 {
                    font-weight: 600;
                    padding: 10px;
                    text-align: center;
                }
            }

            .checkbox-group {
                display: flex;
                flex-direction: row;
                justify-content: center;

                .checkbox-outer {
                    padding: 5px;
                    

                    .checkbox {
                        margin: 5px;
                    }
                }
                
            }

            
        }
        
        .stepradio {
            padding-top: 5px;
            margin-top: 5px;
            border-top: 1px solid lightgray;
            margin-right: 5px;
        }
    }

    .stepbutton {
        background: #0049B7 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 13px;
        border: none;
        width: 90%;
        height: 50px;
        letter-spacing: 0px;
        color: #FFFFFF;
        cursor: pointer;
        font-weight: 600;
        font-size: 1em;
        max-width: 300px;
        margin-top: 50px;
    }

    .stepbutton:disabled {
        background: lightgray 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 13px;
        border: none;
        width: 90%;
        height: 50px;
        letter-spacing: 0px;
        color: white;
        cursor: not-allowed;
        font-weight: 600;
        font-size: 1em;
        max-width: 300px;
        margin-top: 50px;
    }

    .removeButton {
        background: transparent linear-gradient(180deg, #F7F7FD 0%, #F9F9FE 100%) 0% 0% no-repeat padding-box;
        color: red;
        border: none;
        cursor: pointer;
        font-weight: 600;
        margin-left: 10px;

    }

    .removeButton:hover {
        background: red;
        color: white;
    }
}
