html {
    height: 100%;
    width: 100%;
    margin: 0;
    
}

body {
    background: transparent linear-gradient(180deg, #F7F7FD 0%, #F9F9FE 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    width: 100%;
}
.Home {
    height: 100%;
    padding: 30px;
    
    .icmlogo {
        max-width: 400px;
        display: block;
        margin: auto;
    }

    .homescreen {
        display: block;
        text-align: center;
        height: 800px;
        max-width: 400px;
        margin: auto;

        .hometext {
            text-align: center;
            font-size: 1em;
        }

        .homeheader {
            letter-spacing: 0px;
            color: #25253E;
            padding: 20px 20px 2px;
            margin: auto;
            margin-top: 50px;
            font-weight: 600;
            font-size: 2em;
        }

        .homebutton {
            background: #0049B7 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 13px;
            border: none;
            margin-top: 150px;
            width: 100%;
            height: 50px;
            letter-spacing: 0px;
            color: #FFFFFF;
            bottom: 20px;
            cursor: pointer;
            font-weight: 600;
            font-size: 1em;
        }
    }
}
