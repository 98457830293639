.users {
    padding: 20px;
}

.usertable {
    margin-top: 20px;
}

.modalinput {
    padding: 10px;
}

.modalerror {
    color: red;
    text-align: center;
    font-weight: 600;
}

.newuserbutton {
    padding-bottom: 10px;
}

.deluser {
    background: none!important;
    border: none;
    padding: 0!important;
    cursor: pointer;
    color: #1890ff;
    transition: color 0.3s;
}
