html {
    height: 100%;
    width: 100%;
    margin: 0;
    
}

body {
    background: transparent linear-gradient(180deg, #F7F7FD 0%, #F9F9FE 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    width: 100%;
}

.Success {

    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icmlogo {
        max-width: 400px;
        display: block;
        margin: auto;
        padding-bottom: 20px;
    }

    .tick {
        max-width: 200px;
        max-height: 250px;
        display: block;
        margin: auto;
    }

    p {
        text-align: center;
    }
    
}
