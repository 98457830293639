.submissiontable {
    margin: 1% 2% auto 2%;
}

.adminsearchinput {
  width: 15%;
  padding-bottom: 10px;
  
}

.adminsvg {
  width: 25px!important;
}

.deleteButton {
  cursor: pointer;
  border: none;
  background: none;
  text-decoration: none;
  color: red
}

.deleteButton:hover {
  text-decoration: underline;
}

.viewbutton:hover {
  text-decoration: underline;

}

#submissions {
    border-collapse: collapse;
    display: table;
    width: 100%;
    table-layout: auto;
  }
  
  #submissions td, #submissions th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  #submissions tr:nth-child(even){background-color: #d3d3d3;}
  
  #submissions tr:hover {background-color: #ddd;}
  
  #submissions th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: grey;
    color: white;
  }
