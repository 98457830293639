@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.dashboard {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  background: rgb(244,246,250);
  background: linear-gradient(0deg, rgba(244,246,250,1) 0%, rgba(238,238,238,1) 50%, rgba(244,246,250,1) 100%);
}

.navbar {
  min-height: 100vh;
  min-width: 10vw;
  width: 10vw;
  background-color: #0652DD;
}

.navbar img {
  width: 80%;
  margin: auto;
  display: block;
  padding-top: 20px;
}

.navbar h1 {
  text-align: center;
  font-size: 25px;
  color: white;
  font-weight: 500;
}

.navbarTop {
  padding: 15px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  box-sizing: border-box;
}

.navbarLinks {
  padding-top: 30px;
}

.navbarLinks 
.navbarTop h1 {
  font-family: 'Open Sans', sans-serif;
  margin-block-start: 0;
  display: block;
  color: #000000;
  padding: 20px;
  font-weight: 600;
  font-size: 20px;
}

.graph {
  width: 900px;
  padding: 20px;
  background-color: white;
  margin: 10px;
  box-shadow: 4px 0px 10px #0000004d;
}

.accountContainer {
  background-color: white;
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover;
  height: 50px;
  width: 50px;
  margin: 10px;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
}

.accountContainer:hover {
  box-shadow: 0px 0px 10px #000000;
}

.content {
  width: 100%;
}
