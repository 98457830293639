html, body {
    height: 100%;
    
}

body {
    background: transparent linear-gradient(180deg, #F7F7FD 0%, #F9F9FE 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 100%;
    
}

.buttons {
    display: flex;
    justify-content: flex-start;
    padding: 1%;

    button {
        background: #0049B7 0% 0% no-repeat padding-box;
        cursor: pointer;
        color: white;
        padding: 10px 30px;
        border: none;
        border-radius: 5px;
        text-decoration: none;
        margin-right: 20px;
        font-size: 1em;
        font-weight: 600;
        border-style: none;
    }

    .approve {
        background-color: #4CAF50;
    }

    .request {
        background-color: orange;
    }

    .return {
        background: #0049B7 0% 0% no-repeat padding-box;
    }
}

.informationContainer {
    margin: 0px 30px;
    display: flex;
    justify-content: space-around;

    .supplierInformation {
        background-color: white;
        padding: 20px;
        height: auto;
        width: 33.33%;
        margin-right: 10px;
    }

    .accountInformation {
        background-color: white;
        padding: 20px;
        height: auto;
        width: 33.33%;
    }

    .bankInformation {
        background-color: white;
        padding: 20px;
        height: auto;
        width: 33.33%;
        margin-left: 10px;
    }
}

.downloadButton {
    margin: auto;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: white;
    color: #1890ff;
    transition: color 0.3s;
    margin: 5px;
}

.companySelect {
    padding-bottom: 20px;
}

.purchaseCategorySelect {
    padding-top: 20px;
}

.abnInline {
    display: flex;
    justify-content: start;
}

.redCross {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.greenTick {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}


.checkbox {
    margin-right: 10px;
}