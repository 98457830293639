html {
    height: 100%;
    width: 100%;
    margin: 0;
    
}

body {
    background: transparent linear-gradient(180deg, #F7F7FD 0%, #F9F9FE 100%) 0% 0% no-repeat padding-box;
    height: 100%;
    width: 100%;
}

.LoginContainer {
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icmlogo {
        max-width: 400px;
        display: block;
        margin: auto;
    }

    h2 {
        font-size: 2em;
    }

    a {
        text-align: center;
        background: #0049B7 0% 0% no-repeat padding-box;
        font-weight: 600;
        font-size: 1em;
        color: white;
        padding: 20px;
        text-decoration: none;
        border: none;
        border-radius: 5px;
        margin: 20px 0;
    }

    p {
        margin: 2px 0;
    }

    
}
