.icmlogoNav {
    max-width: 100px;
    display: block;
    margin: auto;
    padding-top: 10px;
}


.NavbarItems {
    .NavbarSection {
        padding-top: 20px;
        h2 {
            padding: 0px 10px;
            color: #d8d8d8;
            font-size: 1em;
        }
        a,.fakeLink {
            display: block;
            color: gainsboro;
            font-weight: 400;
            width: 100%;
            cursor: pointer;
            -webkit-transition: color 1000ms linear;
            -moz-transition: color 1000ms linear;
            -o-transition: color 1000ms linear;
            -ms-transition: color 1000ms linear;
            transition: color 1000ms linear;
            max-width: 10vw;
            padding: 10px 0px;
            text-decoration: none;
        }

        span {
            padding-left: 10px;
        }

        .navbarSvg {
            width: 25px!important;
        }

        a:hover, .fakeLink:hover {
            color: white;
            background-color: #0047c7;
        }
        
        .fakeLink {
            padding-left: 10px;
        }

        .navbarLinksSelected {
            color: white !important;
            background-color: #0047c7;
        }

        &.Spaced {
            padding-top: 20%;
        }
        &.Bottom {
            
        }

        
    }
}
